import axios from "axios";
import Modal from "./UI/Modal";
import Form from "./Form/Form";
import Input from "./Form/Input";
import Checkbox from "./Form/Checkbox";
import { APIURL } from "..";
import { useToast } from "./UI/useToast";
import Dropdown from "./Form/Dropdown";
import { useContext, useEffect, useState } from "react";
import Loading from "./UI/Loading";
import { Store } from "../App";

const FormModal = ({ state }) => {
    const { addToast } = useToast();
    const [formData, setFormData] = useState(null);
    let [event, setModalState] = state;
    const [typ, setTyp] = useState(null);
    const [loading, setLoading] = useState(false);
    const [doubleEvent, setDoubleEvent] = useState(false);
    const {setLoadingCard} = useContext(Store);

    const handleSubmit = (data) => {
        setLoading(true);
        var dataPrep = formData?.inputs.map((d, i) => {
            if (d.name == "assistance" || d.name == "car" || d.name == "communication") return ({ id: d.id, value: data[d.name]?.label||'' })
            else return ({ id: d.id, value: data[d.name] })
        })
        dataPrep = dataPrep.filter(i=>(i!==undefined&&i!==null&&i.value!==undefined&&i.value!==null));
        if (typ?.id) {
            dataPrep.push(typ);
        }
        var dataFinal = {
            ...event,
            data: dataPrep,
            language:'cs',
        }

        axios.post(APIURL + '/newAnswer', dataFinal)
            .then(response => {
                var message = event.name == "petice" ? "Děkujeme za váš zájem, detailní informace vám budou zaslány na e-mail, až bude petice spuštěná." : "Děkujeme za registraci na kurz, detailní informace vám budou zaslány na e-mail.";
                addToast({ type: 'success', message: message })
                resetForm()
            }).catch(err => {
                addToast({ type: 'error', message: "Nastala se chyba, kontaktujte nás prosím na admin@agenturagen.cz." })
                resetForm()
            })
    }

    const resetForm = () => {
        setLoading(false);
        setModalState(false);
        setFormData(null);
    }

    const getDateFormat = (input) => {
        if (input) {
            var newDate_from = new Date(input.date);

            const f_yyyy = newDate_from.getFullYear();
            let f_mm = ("0" + (newDate_from.getMonth() + 1)).slice(-2);
            let f_dd = ("0" + newDate_from.getDate()).slice(-2);

            if (input.date_to) {
                var newDate_to = new Date(input.date_to);
                const t_yyyy = newDate_to.getFullYear();
                let t_mm = ("0" + (newDate_to.getMonth() + 1)).slice(-2);
                let t_dd = ("0" + newDate_to.getDate()).slice(-2);

                let m = f_mm < t_mm ? ". " + t_mm : "";
                return f_dd + m + " - " + t_dd + ". " + t_mm + ". " + t_yyyy + " - " +
                    event?.data.name +
                    ", " +
                    event?.data.place;
            } else {
                return f_dd + ". " + f_mm + ". " + f_yyyy + " - " +
                    event?.data.name +
                    ", " +
                    event?.data.place;
            }
        } else { return ("") }
    };
    const getForm = async () => {
        await axios
            .post(APIURL + "/form", { name: event.name, language:'cs' })
            .then((response) => {
                let resp = response.data.body;
                if (event.name!=='petice') {
                    let typeInput = resp.inputs.find(i=>i.name=='type')?.id
                    if (event.data.hearing == 'false') {
                        resp.inputs = resp.inputs.filter(i=>i.name!='communication');
                        setTyp({id: typeInput, value:'Ruční řízení', typ:'mobility'})
                        setDoubleEvent(false)
                    } else if (event.data.mobility == 'false') {
                        resp.inputs = resp.inputs.filter(i=>i.name!='car');
                        setTyp({id: typeInput, value:'Sluchové postižení', typ: 'hearing'})
                        setDoubleEvent(false)
                    } else {
                        setTyp(null)
                        setDoubleEvent(true)
                    }
                }
                setFormData(resp);
                setLoadingCard(null);
            });
    };

    const watchForm = (data) => {
        if (doubleEvent) {
            if (data.type?.label?.includes('Sluch')) {setTyp({typ:'hearing'})}
            else if (data.type?.label?.includes('Ručn')) {setTyp({typ:'mobility'})}
            else {setTyp(null)}
        }
    }

    useEffect(() => {
        event ? getForm() : setFormData(null);
    }, [event]);

    if (formData && event) return (
        <Modal state={state}>
            <div className="formModal form">
                        <div className="perex">
                            <h2>
                                <mark>{formData?.display_name}</mark>
                            </h2>
                        </div>
                        <Form onSubmit={handleSubmit} onWatch={watchForm}>
                            {formData.name == "event_register" && <Input
                                label="Termín"
                                defaultValue={getDateFormat(event && event.data)
                                }
                                name="event_id"
                                placeholder=""
                                disabled
                            />}
                            {formData && formData?.inputs?.sort((a, b) => (a.order > b.order ? 1 : -1)).map((input, index) => {
                                if (
                                    input.type == "input" ||
                                    input.type == "textarea"
                                ) {
                                     return (
                                            <Input
                                                id={input.id}
                                                label={input.label}
                                                name={input.name}
                                                placeholder={input.placeholder}
                                                className={
                                                    (input.type == "input" && event.name != "petice") &&
                                                    "half"
                                                }
                                                required={
                                                    input.required
                                                        ? true
                                                        : false
                                                }
                                                type={input.input_type}
                                            />
                                    );
                                } else if (input.type == "select") {
                                    if (!((input.name=='car'&&typ?.typ=='hearing')||(input.name=='communication'&&typ?.typ=='mobility')||(input.name=='type'&&!doubleEvent))) return (
                                        <Dropdown
                                            id={input.id}
                                            label={input.label}
                                            name={input.name}
                                            required={
                                                input.required ? true : false
                                            }
                                            options={input.options}
                                        />
                                    )
                                    if (input.name=='type'&&!doubleEvent) return (
                                        <Input
                                            label={input.label}
                                            name={input.name}
                                            defaultValue={typ?.value}
                                            placeholder=""
                                            disabled
                                        />
                                    )
                                }
                            })}
                            <Checkbox name="GDPR_form" required>
                                Souhlasím s {" "}
                                <a href="/documents/PV0_GDPR.pdf" target="_blank">
                                    GDPR
                                </a> {formData.name == "event_register" &&
                                    <>  a{" "}
                                        < a href="/documents/PODMINKY-UCASTI-NA-KURZU_PV0.pdf" target="_blank">
                                            Podmínkami účasti
                                        </a>
                                    </>

                                }
                            </Checkbox>
                            {loading?<button className="blue loading"><Loading /></button>:<button type="submit" className="blue">
                                {formData.name == "event_register" ? "Registrovat se" : "Odeslat"}
                            </button>}

                        </Form>
            </div>
        </Modal >
    );
};

export default FormModal;
